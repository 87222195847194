import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import { useMerchant, useMyStore, userDataStore, useSingleItemStore, useStoreAndChild, useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { Loading } from "../../../assets/svgs/Svgs";
import OneProvider from "./OneProvider";
import styles from "./styles.module.css";
import classNames from "classnames";
import { findMinValueResource, sortObjectArrayByOrder } from "../../../utils/helpers";
import TipsWithoutPercents from "./TipsWithoutPercents";
import { InfoSvg } from "../../../assets/svgs/Svgs";
import ToolTip from "../../../components/Tooltip/Tooltip";
import { costLineItemDisplayNames } from "../../../constants";
import { findAmountByName } from "./utils";
import { PARTNER_INDUSTRY_OPTIONS } from "../../../components/ProviderIntegrationSettings/utils";

const order = ["Delivery Fee", "Transaction Fee", "Referral Commission"];

const QuotesList = ({ rerouteItem, edit, isReroute, setSteps }) => {
  const { Colors } = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const { merchantData } = useMerchant();

  const { mdfontsize } = useDesktopOrLaptop();
  const { authInfo } = userDataStore();

  const { getRerouteQuote, isAdminReroute } = useSingleItemStore();

  const [noQuotes, setNoQuotes] = useState();

  const [fastestQuote, setFastestQuote] = useState([]);

  const [selectedQuote, setSelectedQuote] = useState();

  const resetProviderSettings = (quote) => {
    const shouldAllowSignatureOnly =
      merchantData.standardized_industry === PARTNER_INDUSTRY_OPTIONS.pharmacy.toLowerCase() || values.contains_alcohol;
    setFieldValue("provider_settings.signature", shouldAllowSignatureOnly);
    setFieldValue("provider_settings.contactless", false);
    setFieldValue("provider_settings.recipient_minimum_age", 18);
    setFieldValue("provider_settings.age_verification", false);
    if (
      shouldAllowSignatureOnly &&
      Array.isArray(quote?.provider_capability?.undeliverable_actions) &&
      quote.provider_capability.undeliverable_actions?.includes("return_to_store")
    ) {
      setFieldValue("provider_settings.undeliverable_action", "return_to_store");
    } else {
      setFieldValue("provider_settings.undeliverable_action", null);
    }
  };

  const { createQuote, quoteRes, isSandbox, setQuoteRes, userId, setLoadingQuote, loadingQuote } = useMyStore();
  const { selectedStore } = useStoreAndChild();

  useEffect(() => {
    setQuoteRes();
    setNoQuotes();
    setSelectedQuote();
    setFieldValue("quote_id", "");
    if (!isReroute) {
      setFieldValue("tip", 0);
    }
    if (edit) {
      setFieldValue("quote_id", "");

      if (values.pickup[0].pickup_address && values.dropoff[0].dropoff_address) {
        createQuote(values, isSandbox, isAdminReroute ? rerouteItem?.account_id : merchantData?.id, rerouteItem?.store_id ?? "").then(({ data }) => {
          if (data) {
            if (!findAmountByName(rerouteItem.cost_line_items, "Tip")) {
              setFieldValue("tip", (2).toFixed(2));
            }
            setSelectedQuote(data?.quotes[0]);
            setFieldValue("quote_id", data?.quotes[0]?.id);
            resetProviderSettings(data?.quotes[0]);
            setNoQuotes(data.unavailable_providers_info);
          } else {
            setSteps(4);
          }
        });
      }
      return;
    }

    if (isReroute) {
      setFieldValue("quote_id", "");
      setLoadingQuote(true);

      getRerouteQuote(rerouteItem.id).then((data) => {
        if (data?.status == 400) {
          return;
        }
        if (!findAmountByName(rerouteItem.cost_line_items, "Tip")) {
          setFieldValue("tip", (2).toFixed(2));
        }
        // setFieldValue("tip", (2).toFixed(2));
        setLoadingQuote(false);
        setQuoteRes(data?.quotes);
        setSelectedQuote(data?.quotes[0]);
        setFieldValue("quote_id", data?.quotes[0]?.id);
        resetProviderSettings(data?.quotes[0]);
        setNoQuotes(data?.unavailable_providers_info);
      });

      return;
    }

    if ((values.pickup[0].pickup_address || selectedStore) && values.dropoff[0].dropoff_address) {
      const accountId = userId || authInfo.merchantAccountId;
      createQuote(values, isSandbox, accountId, selectedStore?.id).then(({ data }) => {
        if (data) {
          setFieldValue("tip", (2).toFixed(2));
          setSelectedQuote(data?.quotes[0]);
          setFieldValue("quote_id", data?.quotes[0]?.id);
          resetProviderSettings(data?.quotes[0]);
          setNoQuotes(data?.unavailable_providers_info);
        } else {
          setSteps(4);
        }
      });
    }
  }, [isSandbox]);

  useEffect(() => {
    if (quoteRes?.length > 0) {
      setFastestQuote(findMinValueResource(quoteRes, "dropoff_eta", "date"));
    }
  }, [quoteRes]);

  return (
    <>
      <div className={styles.container}>
        <div className="flex items-center justify-start mb-6 ">
          <div className={styles.headerText}>Select service provider</div>
        </div>
        {loadingQuote ? (
          <div className="w-full h-full flex justify-center items-center">
            <Loading color={Colors.bluePrimary} className="h-12 w-12 animate-spin" />
          </div>
        ) : (
          quoteRes?.length < 1 && (
            <div
              style={{ color: Colors.fontGreyLight, fontSize: mdfontsize }}
              className="w-full h-full flex justify-center items-center font-medium"
            >
              There is no available delivery option
            </div>
          )
        )}

        {quoteRes &&
          quoteRes?.length > 0 &&
          quoteRes
            .sort((a, b) => a.total_cost - b.total_cost)
            .map((item) => {
              return (
                <OneProvider
                  key={item.id}
                  quote={item}
                  rerouteItem={rerouteItem}
                  setSelectedQuote={setSelectedQuote}
                  fastestQuote={fastestQuote}
                />
              );
            })}
        {/* {quoteRes &&
          (isTipsWithoutPercentageEnabled ? (
            <TipsWithoutPercents quoteRes={selectedQuote?.total_cost} />
          ) : (
            <TipFormik quoteRes={selectedQuote?.total_cost} />
          ))} */}
        {quoteRes && <TipsWithoutPercents rerouteItem={rerouteItem} quoteRes={selectedQuote?.total_cost} />}
      </div>

      {noQuotes && noQuotes?.length > 0 && (
        <div className={classNames(styles.container, "mt-12")}>
          <div className="flex items-center justify-start mb-6 ">
            <div className={styles.headerText}>Other Providers</div>
          </div>
          {noQuotes.map((item, index) => {
            return (
              <OneProvider
                isNoQuote
                quote={item}
                rerouteItem={rerouteItem}
                index={index}
                key={item.id}
                length={noQuotes?.length}
              />
            );
          })}
        </div>
      )}
      <div className={styles.feesCont}>
        {selectedQuote && selectedQuote.byopk_cost ? (
          <div className={classNames("flex w-1/2 justify-end items-center pt-5")}>
            <div className="flex w-full justify-between">
              <div className={styles.headerText}>Delivery Fee: </div>
              <div className={classNames(styles.headerText)}>
                ${selectedQuote ? (selectedQuote.byopk_cost.delivery_cost / 100).toFixed(2) : 0}
              </div>
            </div>
          </div>
        ) : null}
        {selectedQuote
          ? sortObjectArrayByOrder(selectedQuote?.cost_line_items ?? [], order).map((lineItem, idx) => {
            const tooltipContainerForIconText = (
              <span className={styles.tooltipText}>
                This fee helps Burq cover payment processing fee and customer support cost.
              </span>
            );
            return (
              <div key={idx} className={classNames("flex w-1/2 justify-end items-center pt-5")}>
                <div className="flex w-full justify-between">
                  <div className={styles.headerTextContainer}>
                    <span className={styles.headerText}>{lineItem.name}:</span>
                    {lineItem.name === costLineItemDisplayNames.TRANSACTION_FEE && (
                      <span className="content-center" data-for={`infoToolTip-${idx}`} data-tip>
                        <span className="cursor-pointer">
                          <InfoSvg />
                        </span>
                        <ToolTip
                          anchorSelect="#clickable"
                          clickable
                          backgroundColor="#fff"
                          className={styles.tooltipContainerForIcon}
                          id={`infoToolTip-${idx}`}
                          disable={false}
                          place="right"
                          text={tooltipContainerForIconText}
                        />
                      </span>
                    )}
                  </div>
                  <div className={classNames(styles.headerText)}>${(lineItem.amount / 100).toFixed(2)}</div>
                </div>
              </div>
            );
          })
          : null}

        <div className={classNames("flex w-1/2 justify-end items-center pt-5")}>
          <div className="flex w-full justify-between">
            <div className={styles.headerText}>Tip: </div>
            <div className={classNames(styles.headerText)}>${selectedQuote ? ((values.tip * 100) / 100).toFixed(2) : 0}</div>
          </div>
        </div>
        <div
          className={classNames("w-1/2 border-t-2 justify-end items-center pt-3 mt-3")}
          style={{
            borderColor: Colors.borderPrimary,
          }}
        >
          <div className="flex w-full justify-between">
            <div className={classNames(styles.totalText)}>Total: </div>
            <div className={classNames(styles.totalText, "ml-3")}>
              ${selectedQuote ? ((selectedQuote.total_cost + values.tip * 100) / 100).toFixed(2) : 0}
            </div>
          </div>
        </div>
        {(selectedQuote?.upsell || selectedQuote?.subsidize) && (
          <div
            style={{
              borderColor: Colors.borderPrimary,
            }}
            className={classNames("flex w-1/2 justify-end items-center pt-3 mt-7 border-t-2")}
          >
            <div className="flex w-full justify-between">
              <div className={styles.headerText}>{selectedQuote?.subsidize ? "Subsidy" : selectedQuote?.upsell && "Upsell"}:</div>
              <div className={classNames(styles.headerText)}>
                $
                {selectedQuote?.subsidize
                  ? (selectedQuote?.subsidize / 100).toFixed(2)
                  : selectedQuote?.upsell && (selectedQuote?.upsell / 100).toFixed(2)}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuotesList;
