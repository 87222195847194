import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";

import { incidentBadges } from "./utils";
import { numberWithCommas } from "../../Analytics/utils";
import { ResolvedIcon, ReviewIcon } from "../Svgs";
import {
  calculateCostLineDeliveryAndTransactionFee,
  calculateCostLineDeliveryFee,
  calculateCostLineItemsTotalAmount,
} from "../../../utils/helpers";

import { GoCircleSlash } from "react-icons/go";

const IncidentInfoAndReason = ({ item, isOnlyReason }) => {
  const status = item?.delivery_incident?.status;
  const resolved = item?.delivery_incident?.status === "resolved";

  const tipObject = item?.cost_line_items.find((item) => item.name === "Tip");

  return (
    <div className={styles.card}>
      {!isOnlyReason && (
        <>
          <div
            className={classNames(styles.header, {
              [styles.borderBottom]: !!item?.cancellation_reason || resolved,
            })}
          >
            {resolved ? (
              <div className={styles.icon}>
                <ResolvedIcon />
              </div>
            ) : (
              <div className={styles.icon}>
                <ReviewIcon />
              </div>
            )}

            <span className={classNames(styles.reviewStatement)}>
              {resolved ? "Burq has reviewed this delivery." : "Burq is reviewing this delivery for refund."}
            </span>
            <StatusBadgeReusable
              externalClassName={styles.badgeExtraStyles}
              status={status}
              badgesObject={incidentBadges}
              uppercase
            />
          </div>
          {resolved && (
            <div
              className={classNames(styles.content, {
                [styles.borderBottom]: !!item?.cancellation_reason,
              })}
            >
              <div className={styles.row}>
                <span className={styles.label}>Delivery fee refunded</span>
                <span className={styles.value}>
                  {/* {item?.delivery_incident?.delivery_fee_refunded ? "Yes" : "No"} */}
                  {/* {item?.delivery_incident?.delivery_fee_refunded
                    ? "$" + (calculateCostLineItemsTotalAmount(item.cost_line_items) / 100).toFixed(2)
                    : "No"} */}
                  {item?.delivery_incident?.delivery_fee_refunded
                    ? "$" + (calculateCostLineDeliveryAndTransactionFee(item.cost_line_items) / 100).toFixed(2)
                    : "No"}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>Tip refunded</span>
                <span className={styles.value}>
                  {" "}
                  {item?.delivery_incident?.tip_refunded && tipObject?.amount ? "$" + (tipObject?.amount / 100).toFixed(2) : "No"}
                </span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>Additional refund</span>
                <span className={styles.value}>
                  ${numberWithCommas((item?.delivery_incident?.order_value_refunded_amount / 100)?.toFixed(2))}
                </span>
              </div>
              {item?.delivery_incident?.refund_rejection_reason?.display_name && (
                <div className={styles.row}>
                  <span className={styles.label}>Refund denial reason</span>
                  <span className={styles.value}>{item.delivery_incident.refund_rejection_reason?.display_name}</span>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {item.cancellation_reason && (
        <div>
          <div className={classNames("w-full flex items-center", styles.reasonCont)}>
            <GoCircleSlash className={styles.cancelIcon} size={28} />
            <div className={styles.cancelationTitle}>Cancellation Reason</div>
          </div>
          <div className={classNames("w-full flex items-center", styles.reasonCont)}>"{item.cancellation_reason}"</div>
        </div>
      )}
    </div>
  );
};

export default IncidentInfoAndReason;
