import React from "react";
import SidebarButton from "../SidebarButton";
import styles from "./styles.module.css";
import { useWorkflow } from "../../../store";
import classNames from "classnames";
import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate } from "react-router-dom";

export function SidebarDropdownButton({
  setSidepanelactive,
  setIsBurgerMenuVisible,
  sidepanelactive,
  activeForDropdown,
  active,
  isBurgerMenuVisible,
}) {
  const { activeWorkflow, setActiveWorkflow } = useWorkflow();
  const isBrandingEnabled = useFlag("branding");
  let history = useNavigate();

  return (
    <>
      <SidebarButton
        id="workflowId"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        active="workflows"
        title={"Workflows"}
        isDropDown={isBurgerMenuVisible}
        isBurgerMenuVisible={isBurgerMenuVisible}
      />
      {sidepanelactive === activeForDropdown && (
        <div
          className={classNames(styles.expandedContainer, {
            [styles.smallerCont]: !isBurgerMenuVisible,
          })}
        >
          <div
            onClick={() => {
              setActiveWorkflow("sms");
              history(`/v1/workflows`);
            }}
            className={classNames(styles.buttonTitle, {
              [styles.active]: activeWorkflow === "sms",

              [styles.smallTextCont]: !isBurgerMenuVisible,
            })}
          >
            {!isBurgerMenuVisible ? "Sms" : "Sms Workflows"}
          </div>
          <div
            onClick={() => {
              setActiveWorkflow("quote");
              history(`/v1/workflows/quote`);
            }}
            className={classNames(
              styles.buttonTitle,
              // styles.hoverWidth,
              {
                [styles.active]: activeWorkflow === "quote",
                [styles.smallTextCont]: !isBurgerMenuVisible,
              }
            )}
          >
            {!isBurgerMenuVisible ? "Quote" : "Quote Workflows"}
          </div>
          {/* {isDeliveryPreferencesFFEnabled && (
            <div
              onClick={() => {
                setActiveWorkflow("order");
              }}
              className={classNames(
                styles.buttonTitle,
                // styles.hoverWidth,
                {
                  [styles.active]: activeWorkflow === "order",
                  [styles.smallTextCont]: !isBurgerMenuVisible,
                }
              )}
            >
              {!isBurgerMenuVisible ? "Order" : "Order Workflows"}
            </div>
          )} */}
          {isBrandingEnabled && (
            <div
              onClick={() => {
                setActiveWorkflow("branding");
                history(`/v1/workflows/branding`);
              }}
              className={classNames(
                styles.buttonTitle,
                // styles.hoverWidth,
                {
                  [styles.active]: activeWorkflow === "branding",
                  [styles.smallTextCont]: !isBurgerMenuVisible,
                }
              )}
            >
              {isBurgerMenuVisible ? "Branding" : "Brand"}
            </div>
          )}

          {/* <div
            onClick={() => {
              setActiveWorkflow("order-workflow");
              window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/workflows/order-workflow`;
              history(`/v1/workflows/order-workflow`);
            }}
            className={classNames(
              styles.buttonTitle,
              // styles.hoverWidth,
              {
                [styles.active]: activeWorkflow === "order-workflow",
                [styles.smallTextCont]: !isBurgerMenuVisible,
              }
            )}
          >
            {isBurgerMenuVisible ? "Order Workflow" : "Order"}
          </div> */}
        </div>
      )}
    </>
  );
}
