import React from "react";

import styles from "./styles.module.css";
import { CommonInputWithErrorMessage } from "./CommonInputWithErrorMessage";
import classNames from "classnames";

import { useAccount, useMerchant, userDataStore } from "../../../store";

import { StoresInsideRolesSidebar } from "./StoresInsideRolesSidebar";
import Button from "../../shared/atoms/Button";
import { Formik } from "formik";
import {
  ROLES,
  defaultValidationSchema,
  initialValues,
  roles,
} from "./helpers";
import RolesCards from "./RolesCards";
import InputErrorComp from "../../shared/common/InputErrorComp";
import { useFlag } from "@unleash/proxy-client-react";

export const RolesSidebar = ({ setIsSidePanelOpened }) => {
  const isUserRolesEnabled = useFlag("userRoles");

  const {
    editUserData,
    postAddUserToAccount,
    getUsersOfAccount,
    updateMerchantUserWorker,
    setEditUserData,
  } = useAccount();
  const { merchantData } = useMerchant();
  const { authInfo } = userDataStore();

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues(editUserData)}
        onSubmit={async (val) => {
          const payload = {
            // firstName: val?.first_name ?? "",
            // lastName: val?.last_name ?? "",
            first_name: val?.first_name ?? "",
            last_name: val?.last_name ?? "",
            email: val?.email ?? "",
            user_roles: isUserRolesEnabled
              ? val?.user_roles ?? []
              : [
                  {
                    role_name: "acct_admin",
                    resource_id: authInfo.merchantAccountId,
                  },
                ],
          };
          let res;
          if (editUserData) {
            res = await updateMerchantUserWorker(editUserData.id, payload);
          } else {
            res = await postAddUserToAccount(payload);
          }
          setEditUserData();
          if (res) {
            getUsersOfAccount();
            setIsSidePanelOpened(false);
          }
        }}
        validationSchema={defaultValidationSchema(
          merchantData?.account_type === "connected" ||
            merchantData?.account_type === "platform" ||
            !isUserRolesEnabled
        )}
        enableReinitialize
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          setFieldTouched,
          touched,
          setFieldValue,
        }) => {
          return (
            <>
              <div className={styles.title}>
                {editUserData ? "Edit member roles" : "Add member"}
              </div>
              <div className="flex items-center w-full justify-between mt-3">
                <div className={styles.halfWidthInput}>
                  <CommonInputWithErrorMessage
                    required
                    lable={"First name"}
                    onFocus={() => {
                      setFieldTouched("first_name");
                    }}
                    onChange={handleChange}
                    placeholder={`Enter your first name`}
                    name={"first_name"}
                    value={values.first_name}
                    type="text"
                    error={errors.first_name}
                    touched={touched.first_name}
                  />
                </div>
                <div className={styles.halfWidthInput}>
                  <CommonInputWithErrorMessage
                    required
                    lable={"Last name"}
                    onFocus={() => {
                      setFieldTouched("last_name");
                    }}
                    onChange={handleChange}
                    placeholder={`Enter your last name`}
                    name={"last_name"}
                    value={values.last_name}
                    type="text"
                    error={errors.last_name}
                    touched={touched.last_name}
                  />
                </div>
              </div>

              <div className="mt-5">
                <CommonInputWithErrorMessage
                  required
                  lable={"Email"}
                  onFocus={() => {
                    setFieldTouched("email");
                  }}
                  onChange={handleChange}
                  placeholder={`Enter your email address`}
                  name={"email"}
                  value={values.email}
                  type="text"
                  error={errors.email}
                  touched={touched.email}
                />
              </div>
              {/*  //! ROLES HIDDEN */}
              {isUserRolesEnabled &&
                merchantData?.account_type !== "connected" &&
                merchantData?.account_type !== "platform" && (
                  <>
                    <div className={classNames(styles.title, "mt-6 mb-4")}>
                      Role <span className={styles.required}>*</span>{" "}
                      <InputErrorComp
                        error={
                          errors.role && !values.role ? errors.role : undefined
                        }
                      />
                    </div>
                    {roles.map((role, index) => {
                      return (
                        <RolesCards
                          setFieldValue={setFieldValue}
                          selectedRole={values.role}
                          key={role.roleBE}
                          desc={role.desc}
                          role={role.role}
                          roleBE={role.roleBE}
                        />
                      );
                    })}

                    {values.role === ROLES.STORE_MANAGER && (
                      <StoresInsideRolesSidebar
                        values={values}
                        error={
                          errors.user_roles ? errors.user_roles : undefined
                        }
                        selectedStoresForRoles={values.user_roles}
                        setSelectedStoresForRoles={setFieldValue}
                        backendRole={values.role}
                      />
                    )}

                    {values.role === ROLES.STORE_EMPLOEE && (
                      <StoresInsideRolesSidebar
                        values={values}
                        error={
                          errors.user_roles ? errors.user_roles : undefined
                        }
                        selectedStoresForRoles={values.user_roles}
                        setSelectedStoresForRoles={setFieldValue}
                        backendRole={values.role}
                      />
                    )}
                  </>
                )}

              <div className="w-full flex items-center justify-end h-24 pr-16">
                <Button
                  externalClassName={classNames(styles.button)}
                  text="Save"
                  onClick={handleSubmit}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
