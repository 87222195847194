import React from 'react';
import { useFormikContext } from 'formik';
import styles from "./styles/ageVerification.module.css";
import { InfoSvg } from '../../../../assets/svgs/Svgs';
import ToolTip from '../../../../components/Tooltip/Tooltip';

const AgeVerification = ({ quote }) => {
  const { age_verification } = quote.provider_capability || {};
  const { values, setFieldValue, errors } = useFormikContext();

  const tooltipContent = (
    <span className={styles.tooltipText}>
      Specify the minimum legal age for this medicine to ensure proper age verification during delivery.
    </span>
  );

  if (!age_verification || values.quote_id !== quote.id) return null;

  return (
    <div className={styles.container}>
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          name="provider_settings.age_verification"
          className={styles.checkboxInput}
          checked={values.provider_settings.age_verification}
          onChange={() => setFieldValue("provider_settings.age_verification", !values.provider_settings.age_verification)}
        />
        <label className={styles.checkboxLabel}>Delivery contains age restricted items</label>
      </div>

      {values?.provider_settings?.age_verification && (
        <div className={styles.fieldContainer}>
          <label className={styles.inputLabel}>Minimum Age</label>
          <input
            type='number'
            value={values.provider_settings.recipient_minimum_age}
            onChange={(e) => setFieldValue(
              "provider_settings.recipient_minimum_age",
              e.target.value ? parseInt(e.target.value, 10) : ""
            )}
            className={styles.inputField}
          />
          {errors?.provider_settings?.recipient_minimum_age && (
            <p className={styles.errorStyle}>
              {errors.provider_settings.recipient_minimum_age}
            </p>
          )}
          <span data-for={`infoToolTip-minimum-age`} data-tip>
            <InfoSvg />
            <ToolTip
              anchorSelect="#clickable"
              clickable
              backgroundColor="#fff"
              className={
                styles.tooltipContainer
              }
              id={`infoToolTip-minimum-age`}
              disable={false}
              place="right"
              text={tooltipContent}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default AgeVerification;
